
*{
  /* background-color: #f0f0f0; */
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  /* font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
  font-family: "Jost", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root{
  --font-size-construction:rgba(241, 241, 241, 0.93);
/* user page */
  --main-color:rgb(168,205,118);
  --main-title-color: #1b1a1a;
  --main-subtitle-color:rgb(163, 163, 163);
}
/* Centering content */

.main_body_div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  background-color: #ffffff;
}

.body_content {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
}

/* Scrollbar.css */
/* For WebKit browsers (Chrome, Safari) */
html::-webkit-scrollbar {
  width: 5px; /* Adjust the width of the scrollbar */
}

html::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Background of the scroll track */
}

html::-webkit-scrollbar-thumb {
  background-color: #ddd; /* Scroll thumb color (the handle part) */
  border-radius: 10px;     /* Make it rounded */
}

/* Optional: Add hover effect */
html::-webkit-scrollbar-thumb:hover {
  background-color: #afacac; /* Darken the thumb on hover */
}

/* Firefox scrollbars */
html {
  scrollbar-width: thin; /* For thinner scrollbar */
  scrollbar-color:  #f1f1f1; /* Thumb and track colors */
}

